import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import notesReducer from './slices/notesSlice';
import customPhraseReducer from './slices/customPhraseSlice';
import customNotesReducer from './slices/customNotes';
import noteDetailsReducer from './slices/noteDetialsSlice';
import clinicalToolReducer from './slices/clinicalToolSlice';
import tutorialReducer from './slices/tutorialSlice';
import stripeSubscriptionReducer from './slices/stripeSubscriptionSlice';
import promptsSliceReducer from './slices/promptsSlice';

const persistConfig = {
  key: 'root', 
  storage, 
  whitelist: ['prompts'],
};

const persistedPromptsReducer = persistReducer(persistConfig, promptsSliceReducer);

const store = configureStore({
  reducer: {
    notes: notesReducer,
    stripeSubscription: stripeSubscriptionReducer,
    customPhrases: customPhraseReducer,
    noteTemplates: customNotesReducer,
    noteDetails: noteDetailsReducer,
    clinicalTool: clinicalToolReducer,
    tutorial: tutorialReducer,
    prompts: persistedPromptsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export default store;
