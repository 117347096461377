import {
  Box,
  Button,
  Card,
  CardContent,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getCopyContentFromValue, temoNoteDetailsContent } from '../../utils';
import { CopyAll } from '@mui/icons-material';
import { useCopyToClipboard } from '../../hooks/useCopyFromClipBoard';
import { useDispatch, useSelector } from 'react-redux';
import {
  setnoteDetails,
  updateNoteDetailsBodyJSON,
} from '../../slices/noteDetialsSlice';
import SingleContent from './SingleContent';
import useApiRequest from '../../hooks/useHandleRequests';
import debounce from 'lodash/debounce';
import moment from 'moment';
import MultiContent from './MultipleContent';
const NewNoteDetailsContent = ({
  excludedHeadings,
  handleExcludedHeadings,
  noteDetailType,
  id,
}) => {
  const noteDetails = useSelector((state) => state.noteDetails.noteDetails);
  const [isEdited, setIsEdited] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(null); // Track the currently highlighted card
  const [lastMarkerPosition, setLastMarkerPosition] = useState(-1);
  const { apiRequest: updateNoteContent } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: (data) => {},
    successMessage: 'Note details has been updated',
  });
  // const updatedSummaryJson = noteDetails.summary_json?.map(item => {
  //   if (item.createdAt) {
  //     return {
  //       ...item,
  //       createdAt: moment(item?.createdAt, 'MM/DD/YY | HH:mm').utc().format('MM/DD/YY HH:mm')
  //     };
  //   }
  //   return item;
  // });
  // const updatedNoteDetails = {
  //   ...noteDetails,
  //   summary_json: updatedSummaryJson,

  // };

  const handleF2Press = () => {
    const cards = Object.values(noteDetails[noteDetailType]);
  
    if (!cards || cards.length === 0) return;
  
    let nextIndex = currentCardIndex !== null ? currentCardIndex : -1;
    let nextMarkerPosition = -1;
  
    if (currentCardIndex !== null) {
      const currentCardContent = cards[currentCardIndex]?.content || "";
      nextMarkerPosition = currentCardContent.indexOf(
        "***",
        lastMarkerPosition + 3
      );
  
      if (nextMarkerPosition !== -1) {
        focusOnMarker(currentCardIndex, nextMarkerPosition);
        return;
      }
  
      nextIndex = currentCardIndex + 1;
    }
  
    for (let i = nextIndex; i < cards.length; i++) {
      const cardContent = cards[i]?.content || "";
      nextMarkerPosition = cardContent.indexOf("***");
  
      if (nextMarkerPosition !== -1) {
        setCurrentCardIndex(i);
        focusOnMarker(i, nextMarkerPosition);
        return;
      }
    }
  
    setCurrentCardIndex(null);
    setLastMarkerPosition(-1);
  };
  
  const focusOnMarker = (cardIndex, markerPosition) => {
    const cardElement = document.getElementById(`card-${cardIndex}`);
    const textAreaElement = cardElement?.querySelector("textarea");
  
    if (textAreaElement) {
      textAreaElement.focus();
      textAreaElement.setSelectionRange(markerPosition, markerPosition + 3);
      setLastMarkerPosition(markerPosition);
    }
  };
  
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'F2') {
        e.preventDefault();
        handleF2Press();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentCardIndex, noteDetails, noteDetailType]);

  const edited = localStorage.getItem('Dtool');

  if(edited){
    updateNoteContent(`/v2/note/${id}`, 'put', {
      body_json: noteDetails[noteDetailType],
      summary_json: noteDetails[noteDetailType]
      // body: updatedNoteDetails[noteDetailType].reduce((prev, value) => prev + getCopyContentFromValue(value) + '\n', '')
    });
    localStorage.removeItem('Dtool');
  }
  useEffect(() => {
    if (isEdited) {
      const debouncedUpdate = debounce(() => {
        updateNoteContent(`/v2/note/${id}`, 'put', {
          body_json: noteDetails[noteDetailType],
          summary_json: noteDetails[noteDetailType]
          // body: updatedNoteDetails[noteDetailType].reduce((prev, value) => prev + getCopyContentFromValue(value) + '\n', '')
        });
        setIsEdited(false);
      }, 500);

      debouncedUpdate();

      return () => {
        debouncedUpdate.cancel();
      };
    }
  }, [isEdited]);
  return (
    <React.Fragment>
      <Card>
        <CardContent>
          {noteDetails[noteDetailType] &&
            Object.values(noteDetails[noteDetailType]).map(
              (summaryItem, index) => {
                const isHighlighted = index === currentCardIndex;
                if (
                  summaryItem.key === 'ekg' ||
                  summaryItem.key === 'care-update'
                ) {
                  return (
                    <MultiContent
                      key={index}
                      disableHeadingEdit
                      copyChecked={
                        !excludedHeadings.includes(summaryItem?.heading)
                      }
                      excludedHeadings={excludedHeadings}
                      handleExcludedHeadings={handleExcludedHeadings}
                      setIsEdited={setIsEdited}
                      noteDetailType={noteDetailType}
                      value={summaryItem}
                      itemKey={index}
                      isHighlighted={isHighlighted}
                      onDropdownReplace={(replacement) => {
                        summaryItem.content = summaryItem.content.replace(
                          '***',
                          replacement
                        );
                        setIsEdited(true);
                      }}
                    />
                  );
                } else {
                  return (
                    <SingleContent
                      key={index} 
                      disableHeadingEdit
                      copyChecked={
                        !excludedHeadings.includes(summaryItem?.heading)
                      }
                      excludedHeadings={excludedHeadings}
                      handleExcludedHeadings={handleExcludedHeadings}
                      setIsEdited={setIsEdited}
                      noteDetailType={noteDetailType}
                      value={summaryItem}
                      itemKey={index}
                      isHighlighted={isHighlighted}
                      onDropdownReplace={(replacement) => {
                        summaryItem.content = summaryItem.content.replace(
                          '***',
                          replacement
                        );
                        setIsEdited(true);
                      }}
                    />
                  );
                }
              }
            )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default NewNoteDetailsContent;
