import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasActiveSubscription: false,
  subscriptionDetails: null,
  loading: false,
  error: null,
};

const stripeSubscriptionSlice = createSlice({
  name: "stripeSubscription",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    
    setSubscriptionData(state, action) {
      state.hasActiveSubscription = action.payload.hasActiveSubscription;
      state.subscriptionDetails = action.payload.subscriptionDetails;
      state.loading = false;
      state.error = null;
    },

    setError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    clearSubscriptionData(state) {
      state.hasActiveSubscription = false;
      state.subscriptionDetails = null;
      state.loading = false;
      state.error = null;
    },
  },
});

export const {
  setLoading,
  setSubscriptionData,
  setError,
  clearSubscriptionData,
} = stripeSubscriptionSlice.actions;

export default stripeSubscriptionSlice.reducer;