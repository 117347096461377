import * as yup from 'yup';
import { ageUnits, genders, locationType, statuses } from '.';

export const customPhraseFormSchema = yup.object().shape({
  code: yup.string().required('code is requuired'),
  title: yup.string().required('title is required'),
  content: yup.string().required('content is requird')
})


export const patientInformationSchema = yup.object().shape({
  innitials: yup
    .string().transform((value) => value?.toUpperCase()).nullable(),
  age: yup
    .number().nullable()
    .typeError('Age must be a number')
    .positive('Age must be positive')
    .integer('Age must be an integer')
    .required('Age is required'),
  ageType: yup
    .string().nullable()
    .required('Age unit is required'),
  sex: yup
    .string().nullable()
    .oneOf(genders.map(item => item.value), 'Sex must be either Male, Female')
    .required('Sex is required'),
  location: yup
    .number().nullable()
    .positive("location must be a number")
    .integer("Location must be a integer"),
  locationType: yup
  .string().nullable(),
  status: yup
    .string().nullable()
});

export const createNoteSchema = yup.object().shape({
  innitials: yup
    .string(),
  age: yup
    .number()
    .typeError('Age must be a number')
    .positive('Age must be positive')
    .integer('Age must be an integer')
    .nullable(),
  ageType: yup
    .string()
    .oneOf(ageUnits.map(item => item.value), 'Age unit must be present in options"')
    .nullable(),
  sex: yup
    .string()
    .oneOf(genders.map(item => item.value), 'Sex must be either Male, Female')
    .nullable(),
  location: yup.string(),
  locationType: yup
  .string()
});