import { Box, Button, Card, CardContent, CircularProgress, Stack, Typography, Grid, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Check } from '@mui/icons-material';
import useApiRequest from '../../hooks/useHandleRequests';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const Subscription = () => {
  const { hasActiveSubscription, subscriptionDetails } = useSelector((state) => state.stripeSubscription);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [localLoadingState, setLocalLoadingState] = useState({}); // Tracks loading state for each card
  const [openDialog, setOpenDialog] = useState(false); // State for dialog visibility
  const { apiRequest: getSubscriptionsData, loading: IsFetching } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: (data) => {
      setSubscriptionData(data?.products || []);
    },
    showSuccessSnackbar: false,
  });

  const { apiRequest: createInvoice } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: (data) => {
      console.log('data', data)
      const url = data?.intentObj?.url;
      window.open(url, '_blank', 'noopener,noreferrer');
    },
    successMessage: 'Invoice has been created',
  });

  const dispatch = useDispatch();
  const { apiRequest: getStripeStatus } = useApiRequest({
    handleError: (err) => {
      console.log(err);
    },
    handleResponse: (data) => {
      dispatch(
        setSubscriptionData({
          hasActiveSubscription: data.hasActiveSubscription,
          subscriptionDetails: data.subscription,
        })
      );
    },
    showSuccessSnackbar: false,
  });

  useEffect(() => {
    getSubscriptionsData('/v2/stripe/products');
    // getStripeStatus(`/v2/stripe/status`)
  }, []);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  if (IsFetching)
    return (
      <Stack direction="row" justifyContent="center" alignItems="center" margin="15px">
        <CircularProgress />
      </Stack>
    );

  const planTypes = ['Monthly', 'Quarterly', 'Yearly'];

  return (
    <Box component="div">
      <Typography textAlign="left" component="h3" fontWeight={700} lineHeight="3rem" fontSize="32px">
        The right plan for you, and for your time.
      </Typography>
      <Typography textAlign="left" marginTop="5px" component="h5" variant="body1" fontWeight={500} fontSize="16px">
        Extend your capabilities, get more done with your time, and help more patients than ever. Choose the plan that fits your needs the best.
      </Typography>

      <Grid container spacing={3} marginTop={2}>
        {planTypes.map((type) => {
          const subscription = subscriptionData.map((data) => {
            const price = data.prices && data.prices.find((price) => {
              if (type === 'Monthly') return price?.unit_amount === 8000;
              if (type === 'Quarterly') return price?.recurring?.interval_count === 3;
              if (type === 'Yearly') return price?.recurring?.interval === 'year';
              return false;
            });
            return { ...data, price, subscriptionType: type };
          });

          return subscription.map((data, index) => {
            const isActive =
              hasActiveSubscription && subscriptionDetails?.priceId === data?.price?.id;
            const loading = localLoadingState[data?.product?.id];

            const monthlyPrice =
              type === 'Quarterly'
                ? (data?.price?.unit_amount || 0) / 300
                : type === 'Yearly'
                ? (data?.price?.unit_amount || 0) / 1200
                : (data?.price?.unit_amount || 0) / 100;

            const savings =
              type === 'Quarterly'
                ? (3 * 8000 - (data?.price?.unit_amount || 0)) / 100
                : type === 'Yearly'
                ? (12 * 8000 - (data?.price?.unit_amount || 0)) / 100
                : 0;

            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    borderRadius: 3,
                    boxShadow: 4,
                    p: 2,
                    margin: '0px auto',
                    position: 'relative',
                    minHeight: '300px',
                    border: isActive ? '2px solid #2E6FF3' : '1px solid #ddd',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  {isActive && (
                    <Chip
                      label="Active Plan"
                      color="primary"
                      sx={{ position: 'absolute', top: 10, right: 10, fontWeight: 300 }}
                    />
                  )}
                  <CardContent sx={{p: 0}}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: isActive ? 3 : 0, textAlign: 'center' }}>
                      {`${data?.product?.name} (${type})`}
                    </Typography>
                    <Typography variant="h4" component="div" sx={{ fontWeight: 'medium', mt: 1, textAlign: 'center' }}>
                      {`$${monthlyPrice.toFixed(2)}`} <Typography variant="body1" component="span">/month</Typography>
                    </Typography>
                    {savings > 0 && (
                      <Typography
                        variant="body2"
                        sx={{ textAlign: 'center', color: 'green', fontWeight: 'medium', mt: 1 }}
                      >
                        Save ${savings.toFixed(2)} compared to Monthly plan!
                      </Typography>
                    )}
                    <Stack direction="column" spacing={1} sx={{ my: 2 }}>
                      {data.product?.metadata?.features &&
                        data.product?.metadata?.features.split(',').map((option, i) => (
                          <Box key={i} display="flex" alignItems="center">
                            <Check color="primary" />
                            <Typography variant="body1" sx={{ ml: 1 }}>
                              {option.trim()}
                            </Typography>
                          </Box>
                        ))}
                    </Stack>
                  </CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    {isActive ? (
                      <Button
                        variant="outlined"
                        sx={{ color: '#FF0000', width: '80%' }}
                        size="medium"
                        onClick={handleOpenDialog}
                      >
                        Cancel Subscription
                      </Button>
                    ) : (
                      <Button
                        onClick={async () => {
                          const id = data?.product?.id;
                          setLocalLoadingState((prevState) => ({ ...prevState, [id]: true }));
                          try {
                            await createInvoice('v2/stripe/subscription', 'post', {
                              priceId: data?.price?.id,
                            });
                          } catch (error) {
                            console.error('Error creating subscription:', error);
                          } finally {
                            setLocalLoadingState((prevState) => ({ ...prevState, [id]: false }));
                          }
                        }}
                        variant="contained"
                        sx={{ backgroundColor: '#2E6FF3', width: '80%' }}
                        size="medium"
                        disabled={!!localLoadingState[data?.product?.id]}
                      >
                        {/* {localLoadingState[data?.product?.id] ? (
                          <CircularProgress size={24} sx={{ color: '#fff' }} />
                        ) : ( */}
                          Buy Now
                        {/* )} */}
                      </Button>
                    )}
                  </Box>
                </Card>
              </Grid>
            );
          });
        })}
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Unsubscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you want to unsubscribe, please email our customer support at <strong>support@drh.ai</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Subscription;
