import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  prompts: [], 
  selectedPrompt: null,
  loading: false,
  error: null,
};

const promptsSlice = createSlice({
  name: 'prompts',
  initialState,
  reducers: {
    setPrompts: (state, action) => {
      state.prompts = action.payload;
    },
    setSelectedPrompt: (state, action) => {
      state.selectedPrompt = action.payload.prompt_title;
    },
    addPrompt: (state, action) => {
      state.prompts.push(action.payload);
    },
    updatePrompt: (state, action) => {
      const { updatedData } = action.payload;
      if (!updatedData?.id) {
        console.error("Invalid updatedData received:", updatedData);
        return;
      }
      if (!state.prompts.length) {
        state.prompts = [updatedData];
        return;
      }

      const index = state.prompts.findIndex(prompt => prompt.id === updatedData.id);
      
      if (index !== -1) {
        state.prompts[index] = {
          ...state.prompts[index],
          ...updatedData
        };
      } else {
        state.prompts.push(updatedData);
      }

      if (state.selectedPrompt?.id === updatedData.id) {
        state.selectedPrompt = updatedData;
      }
    },
    deletePrompt: (state, action) => {
      state.prompts = state.prompts.filter((prompt) => prompt.id !== action.payload);
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetPromptsState: () => initialState,
  },
});

export const {
  setPrompts,
  setSelectedPrompt,
  addPrompt,
  updatePrompt,
  deletePrompt,
  setLoading,
  setError,
  resetPromptsState,
} = promptsSlice.actions;

export default promptsSlice.reducer;