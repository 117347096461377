import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useApiRequest from "../../hooks/useHandleRequests"; 
import { setPrompts } from "../../slices/promptsSlice"; 
import { useUser } from "@clerk/clerk-react"; 

const Prompts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useUser();
  const selectedPromptTitle = useSelector((state) => state?.prompts?.prompts[0]?.prompt_title);
  const [activePrompt, setActivePrompt] = useState(selectedPromptTitle || "Default Template");

  const { apiRequest } = useApiRequest({
    handleResponse: (response) => {
      if (response?.prompt?.prompt_title) {
        dispatch(setPrompts([response.prompt]));
        setActivePrompt(response.prompt.prompt_title);
      }
    },
    handleError: (error) => console.error("Failed to fetch prompt:", error),
    showSuccessSnackbar: false,
    showErrorSnackBar: true,
  });

  useEffect(() => {
    if (!selectedPromptTitle && user?.id) {
      apiRequest(`/v2/prompt/${user.id}`, "get");
    }
  }, [selectedPromptTitle, user, apiRequest]);

  const handleCardClick = (name) => {
    const formattedName = name.toLowerCase().replace(/\s+/g, "-");
    navigate(`/prompt/${formattedName}`);
  };

  const prompts = [
    {
      title: "Default Template",
    },
    {
      title: "Hospitalist Template",
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#F4F7FC",
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        pt: 5,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
          color: "#1A202C",
          mb: 4,
        }}
      >
        Select the note template
      </Typography>

      <Grid container spacing={3} justifyContent="start">
        {prompts.map((prompt) => (
          <Grid
            item
            key={prompt.title}
            xs={12}
            sm={6}
            md={3}
            sx={{ display: "flex", justifyContent: "start" }}
          >
            <Card
              sx={{
                width: "290px",
                height: "90px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                background:
                  activePrompt === prompt.title
                    ? "linear-gradient(90deg, #4e8eff 0%, #1A73E8 100%)"
                    : "linear-gradient(90deg, #2E6FF3 0%, #4e8eff 100%)",
                color: "#fff",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: 2,
                transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                "&:hover": {
                  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
                  transform: "scale(1.03) translateY(-5px)",
                  cursor: "pointer",
                },
              }}
              onClick={() => handleCardClick(prompt.title)}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  width: 24,
                  height: 24,
                  backgroundColor: activePrompt === prompt.title ? "#fff" : "transparent",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow:
                    activePrompt === prompt.title ? "0px 4px 6px rgba(0, 0, 0, 0.2)" : "none",
                }}
              >
                {activePrompt === prompt.title && (
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#2E6FF3",
                    }}
                  >
                    ✓
                  </Typography>
                )}
              </Box>
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  {prompt.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Prompts;
