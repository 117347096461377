import React from 'react';
import { Box, Typography, Button, Card, CardContent } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';

const Cancel = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bgcolor="#f5f5f5"
      textAlign="center"
    >
      <Card
        sx={{
          maxWidth: 400,
          padding: 4,
          borderRadius: 3,
          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
        }}
      >
        <CardContent>
          <ErrorOutlineIcon
            sx={{ fontSize: 60, color: '#f44336', marginBottom: 2 }}
          />
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            Subscription Cancelled
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            We're sorry to see you go. If this was a mistake, you can try
            subscribing again.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/subscription')}
            sx={{
              marginTop: 3,
              paddingX: 4,
              borderRadius: 50,
              background: 'linear-gradient(90deg, #f44336, #d32f2f)',
            }}
          >
            Go to Subscription
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Cancel;
