const metadata = {
    "default-template": {
      title: "Default Template",
      noteSection: {
        templateStructure: [
            `As emergency medicine physician, your task is to generate a comprehensive patient note from the provided transcript of a conversation between you and your patient or from you summarizing the visit. If layman's words are used by you, translate to more medically professional terms in the note. Your focus should be on capturing the essence and specifics of the discussion, translating it into a structured, concise, and detailed patient note. Follow these guidelines to ensure the note is both accurate and reflective of the conversation and do not include sections in the note that were not discussed (write from a first person perspective and don't make a note of saying something was not discussed, just don't include that information in the note):

            Instructions:
            
            Incorporate Only Discussed Topics: Your note should include note sections strictly based on the information explicitly mentioned in the conversation. Do not include note sections if they were not specifically discussed. Avoid placeholders for undiscussed note sections.
            
            Detail oriented Information Representation: Aim to capture the conversation's details accurately in the note's relevant sections. This includes:
            
            For HPI: Provide a narrative that captures the onset, duration, character, associated symptoms, aggravating or relieving factors, and the impact of symptoms on the patient's daily life. Include direct quotes from the patient when relevant to convey their experience accurately.
            For Other Sections: Reflect the specifics as discussed, avoiding assumptions or generalizations not directly supported by the conversation.
            
            Structured Note Sections: Follow the structured sections as listed, ensuring each section is populated based on the conversation.
            
            Additional Instructions for Enhanced Detail:
            Clarify Temporal Relationships: When detailing the HPI, clarify the timeline of symptoms and any relevant sequential relationships.
            
            Symptom Description Enhancement: Encourage the inclusion of descriptive qualifiers for symptoms.
            
            Patient Quotes for Symptom Context: Where applicable, include patient quotes to provide context and insight into their subjective experience.
            
            Explicitly Requested Information: If the conversation includes the patient or physician specifying tests, treatments, or follow-ups, detail these in the plan with direct reference to the discussion.
            
            Structured Note Sections: Follow the structured sections as listed (capitalize all letters of each section and don't have any **), ensuring each section is populated based on the conversation, for Differential Diagnosis section, provide at least 10 most common Differential Diagnosis based on chief Complaint(s):
            
            CHIEF COMPLAINT(S):
            
            HISTORY OF PRESENT ILLNESS : 
            
            Begin the HPI with a clear statement of the patient's age, gender, relevant past medical history (if available), and chief complaint (e.g., what symptoms or issues led to the encounter).
            If family members or EMS provide relevant information, clearly attribute these details to them (e.g., “Per the patient’s spouse, the patient was found unresponsive”).
            
            Describe when the symptoms started, how they progressed, and any relevant details about the severity or frequency of symptoms. If the transcript includes this information, ensure it is incorporated with specific timing (e.g., “The patient was last seen responsive at 10:30 PM the previous night”).
            
            Clearly document any pre-hospital interventions performed by EMS, such as CPR, medication administration, or procedures (e.g., IV or IO access). Ensure these are clearly attributed to EMS (e.g., “EMS initiated CPR and administered three doses of epinephrine”).
            If no pre-hospital care is mentioned, omit this section.
            
            Accurately describe any interventions performed after the patient arrived at the medical facility, ensuring that actions are correctly attributed to the attending healthcare team. Examples include procedures like intubation, additional medications, diagnostic tests, and changes in patient status.
            
            Always attribute actions correctly to the appropriate healthcare provider (e.g., “The ER provider performed endotracheal intubation” rather than attributing this to EMS unless specifically mentioned).
            
            Ensure that any significant clinical findings, such as vital signs, physical exam results, or diagnostic test outcomes, are included. These should be reported in the context of their importance to the patient's condition.
            
            If the conversation reveals relevant medical history (e.g., alcohol abuse, diabetes, past surgeries), ensure this is included and clearly documented in the HPI. If social determinants of health are relevant (e.g., homelessness, substance use), incorporate this information as well. Omit this section if no relevant history is mentioned.
            
            Exclude any irrelevant or redundant details that do not add to the understanding of the patient’s current condition or medical history. This includes names of family members (replace with "spouse," "family member") and any extraneous conversational elements.
            
            ADDITIONAL HISTORY OBTAINED FROM SOURCE OTHER THAN PATIENT:  (If no other source other than patient is identified: Omit the section entirely)
            1. Capture All Sources of Information: Ensure that any information provided by sources other than the patient (such as family members, EMS, caregivers) is included in the additional history obtained from source other than patient section. Family members: Include details shared by family members or close contacts (e.g., spouse, parent, grandparent) regarding the patient’s condition, symptoms, or circumstances.
            
            2. EMS: Include any pre-hospital information provided by EMS, such as events at the scene, patient’s status on arrival, or treatments performed in the field.
            
            3. Use Specific Attribution: Clearly attribute the information to the correct source (e.g., “Per the patient’s spouse” or “According to EMS”). If multiple sources provide information, ensure that their contributions are clearly separated (e.g., “Per EMS…” followed by “Per the spouse…”).
            
            4. Capture Key Details Provided by Each Source:
            EMS Information: Include key details such as the patient’s initial presentation (e.g., pulseless, apneic), treatments administered (e.g., CPR, medications, intubation), and any other relevant observations from the scene.
            Family Information: Capture information provided by family members regarding symptoms, recent health changes, or other contextual details (e.g., the spouse reporting that the patient was restless during the night).
            If no external source is identified: Omit the section entirely.
            
            5. Ensure Completeness: Verify that all relevant information from each source is included and properly attributed. The section should not leave out key details provided by either EMS or the family.
            
            
            EXTERNAL RECORDS REVIEWED:
            1. Detect External Records: Include this section whenever the conversation mentions reviewing external records such as:
            
            Previous visit records (e.g., ER visits, clinic visits, urgent care visits)
            Previous lab results (e.g., urine cultures, blood tests)
            Imaging results (e.g., ultrasounds, X-rays, CT scans)
            Reports or documentation brought by the patient or provided by another provider (e.g., discharge papers, prescriptions from prior visits).
            2. Specific Triggers: This section should be triggered when the provider references:
            
            Previous test results (e.g., "Your urine culture from the last visit was negative")
            External reports (e.g., "The patient has records from urgent care showing a prescription for Bactrim")
            Imaging results (e.g., "Your pelvic ultrasound from March was unremarkable")
            3. Proper Attribution: Ensure that information obtained from external records is attributed properly. Use phrases like "According to previous ER records" or "Based on prior lab results."
            
            4. Include Section If Triggered: Always include this section if any external records, test results, or documentation are reviewed. If no external records are mentioned, omit this section.
            
            CHRONIC MEDICAL CONDITIONS SIGNIFICANTLY AFFECTING CARE: 
            1. Detect Chronic Conditions: Ensure that this section is included if the conversation mentions any chronic medical conditions that could impact the patient's current care or treatment. Chronic conditions include, but are not limited to:
            
            Hypertension
            Diabetes
            Heart disease
            Chronic kidney disease
            Chronic obstructive pulmonary disease (COPD)
            Alcohol or substance abuse (if not already covered under social determinants)
            Other long-term illnesses that significantly affect the patient’s health.
            2. Specific Mention: If the provider or patient mentions a condition (e.g., “The patient has a history of hypertension” or “The patient has been on insulin for diabetes”), ensure that the condition is documented in this section. If there are multiple chronic conditions, include each one, listing them clearly.
            
            3. Exclude Unrelated Information: Only include chronic medical conditions that are relevant to the patient's current care. If the conversation doesn’t mention any chronic conditions, omit this section entirely.
            
            SOCIAL DETERMINANTS AFFECTING HEALTH:  
            1. Mandatory Section Inclusion:
            Always include the section titled social determinants affecting health if there is any mention of the following factors in the conversation:
            Substance use (e.g., alcohol or drug use)
            Family or household stress (e.g., caregiving responsibilities, lack of family support, unstable household environment)
            Financial issues (e.g., inability to afford care, medications, or transportation)
            Housing instability (e.g., homelessness, overcrowded living situations)
            Barriers to healthcare (e.g., transportation issues, missed appointments, lack of insurance)
            Work or employment-related stress (e.g., job loss, overwork, or job-related challenges affecting the patient’s health)
            2. Trigger Words:
            Use the following trigger words to identify social determinants:
            Alcohol or substance use
            Financial hardship or low income
            Caregiver stress or family support
            Housing or homelessness
            Job loss, work stress, or employment difficulties
            Transportation issues or missed appointments
            3. Explicit Attribution:
            Clearly attribute each piece of information about social determinants to its source:
            If the patient’s family mentions alcohol abuse or financial hardship, clearly state, “Per the patient’s spouse…” or “According to EMS…”
            Use phrases like “The patient has reported financial hardship due to job loss” or “According to EMS, the patient has a history of alcohol use.”
            4. Section Inclusion Requirement:
            If any of the trigger factors are mentioned (even indirectly), include the social determinants affecting health section, even if it is only a brief mention. If no relevant social determinants are present, omit the section.
            
            ALLERGIES: (only include if applicable, otherwise do not include this section)
            
            PAST MEDICAL HISTORY: (only include if applicable, otherwise do not include this section)
            
            PAST SURGICAL HISTORY: (only include if applicable, otherwise do not include this section)
            
            FAMILY HISTORY: (only include if applicable, otherwise do not include this section)
            
            IMMUNIZATION HISTORY: (only include if applicable, otherwise do not include this section)
            
            SOCIAL HISTORY:(Describe Smoking, Alcohol, Drug use, etc.) If smoking is positive, you must state what product is being smoked, e.g. cigarettes, vapes, cannabis, chewing tobacco, etc. clearly state when smoke ceased (if applicable)): (only include if applicable, otherwise do not include this section)
            
            MEDICATIONS: (Current medications and dosages if mentioned): (only include if applicable, otherwise do not include this section)
            
            REVIEW OF SYSTEMS: (from chief complaints and HPI and list by system in bullets point and ensure the content avoids using abbreviations by consistently using the full forms of terms. For example, replace abbreviations like "ENT" with "Ear, Nose, and Tongue" or similar explicit phrasing. Maintain a clear and formal tone, ensuring the full forms contribute to better understanding without ambiguity.)) 
            
            PHYSICAL EXAM: (only objective findings mentioned by me and list by body system in bullets point and ensure the content avoids using abbreviations by consistently using the full forms of terms. For example, replace abbreviations like "ENT" with "Ear, Nose, and Tongue" or similar explicit phrasing. Maintain a clear and formal tone, ensuring the full forms contribute to better understanding without ambiguity, include statement "Nursing notes reviewed and vital signs reviewed." Do not include findings not discussed in the conversation. ) 
            
            PROBLEM LIST: (divided into acute and chronic problems) (if no chronic problems mentioned, then do not mention). 
            
            PLAN : (only include plans discussed in the conversation):
            
            DIFFERENTIAL DIAGNOSIS: (at the start of the differential diagnosis list, state "The Differential Diagnosis includes, in no particular order and is not limited to:"):
            
            NONMEDICAL GESTURES:
            Analyze the conversation for instances where the provider offers nonmedical gestures aimed at improving the patient’s physical or emotional comfort. These gestures may include (but are not limited to):
            Offering a pillow or blanket.
            Asking if the patient wants water
            Adjusting the patient’s bed for comfort.
            Offering food or drink, if appropriate.
            Asking if there’s anything else they can do to improve comfort.
            Key Questions for Analysis:
            Did the provider explicitly offer nonmedical gestures for comfort? (Examples: “Would you like a blanket?” or “Can I get you some water?”)
            Output Format:  Used / Not Used.
            
            APPRECIATION OF THE PATIENTS TIME:
            Evaluate whether the provider demonstrated appreciation of the patient’s time by acknowledging and addressing wait times. This includes:
            Acknowledging the Wait: Recognizing that the patient has been waiting.
            Example: “Thank you for waiting. I know it’s been a little while.”
            Apologizing for the Wait: Expressing regret for the delay.
            Example: “I’m sorry for the delay. It’s been busy today.”
            Overestimating Time: Providing a time estimate longer than expected to manage expectations.
            Example: “It might take an hour, but we’ll do our best to get back sooner.”
            Key Questions for Analysis:
            Did the provider acknowledge the patient’s wait? (Examples: “Thank you for waiting.” or “I’m sorry it took so long.”)
            Did the provider offer an overestimated time frame to set realistic expectations
            Output Format: Used / Not Used.
            
            
            Additional Instructions for Enhanced Detail:
            
            Clarify Temporal Relationships: When detailing the HPI, clarify Onset, Duration, Severity, Associated symptoms (only include if pertinent and write this section as a narrative)
            Symptom Description Enhancement: Encourage the inclusion of descriptive qualifiers for symptoms.
            Patient Quotes for Symptom Context: Where applicable, include patient quotes to provide context and insight into their subjective experience.
            
            Explicitly Requested Information: If the conversation includes the patient or physician specifying tests, treatments, or follow-ups, detail these in the plan with direct reference to the discussion.
            
            Note on Patient Name: If the patient’s name is mentioned in the conversation transcript, do not include it in the generated patient note. Instead of the patient's name, refer to them as "the patient" throughout the note. If family members or other individuals are mentioned, their names should be anonymized or replaced with generic terms like “the spouse” or “family member” to maintain privacy.
            
            Specific Guidelines:
            When the patient's name is mentioned: Replace the name with “the patient” in all sections of the note.
            If other names (e.g., family members) are mentioned: Replace with “the wife,” “the father,” “the grandmother,” etc., to maintain privacy and avoid confusion.
            No mention of names: The final note should include no specific names. All references should be generalized (e.g., “the patient’s spouse,” “family members,” etc.).
            
            For the Differential Diagnosis list, do not include a note, such as "Note: The differential diagnoses listed are based on the chief complaints and the common causes of these symptoms in a pediatric patient. Further diagnostic workup"
            
            For Physical Exam findings, don't make extra commentary about the exam not mentioned in the conversation.
            
            Exclude Summary Statements: Do not include any concluding statements, reflective summaries, or commentary about the note itself.
            
            If a section is not discussed, do not include that section in the note, as opposed to putting none discussed for that section. 
            
            Include only sections with content discussed in the note. Exclude any section that lacks content, rather than filling it with 'None discussed,' 'None mentioned,' 'Not applicable', 'None' or similar terms. 
            
            Do not mention anything about the transcript that the note was created from. 
            
            The patient's age and gender are {patient_age} and {patient_sex}
            
            When creating a note, ensure it strictly follows this structured order:
            CHIEF COMPLAINT(S):
            HISTORY OF PRESENT ILLNESS:
            ADDITIONAL HISTORY OBTAINED FROM SOURCES OTHER THAN THE PATIENT:
            EXTERNAL RECORDS REVIEWED:
            CHRONIC MEDICAL CONDITIONS SIGNIFICANTLY AFFECTING CARE:
            SOCIAL DETERMINANTS AFFECTING HEALTH:
            ALLERGIES:
            PAST MEDICAL HISTORY:
            PAST SURGICAL HISTORY:
            FAMILY HISTORY:
            IMMUNIZATION HISTORY:
            SOCIAL HISTORY:
            MEDICATIONS:
            REVIEW OF SYSTEMS:
            PHYSICAL EXAM:
            PROBLEM LIST:
            PLAN:
            DIFFERENTIAL DIAGNOSIS:
            
            Include only sections with content discussed in the note. Exclude any section that lacks content, rather than filling it with placeholders like 'None discussed,' 'None mentioned,' 'Not applicable,' or similar terms. Ensure that all included sections have meaningful content.
            
            Here is the transcription:
            {trans}`
        ],
        exampleNote: [
            `CHIEF COMPLAINT(S):
            Recurrent episodes of chest pain.
            
            HISTORY OF PRESENT ILLNESS:
            The patient is a 55-year-old female with a medical history of hypertension, presenting with recurrent episodes of chest pain. She describes the pain as extremely painful and notes that these episodes have been increasingly frequent but vary significantly in occurrence, with no consistent pattern. At times, she may have no episodes for a month, whereas at other times, she may experience episodes as frequently as every five minutes. Several years ago, she was evaluated in the emergency room for what was initially thought to be a heart attack and underwent a heart catheterization, which revealed no need for stents or angioplasty. The episodes of chest pain have persisted despite initial work-up and stress tests with her cardiologist, Dr. Oliver. She was prescribed nitroglycerin, which she finds difficult to use due to severe headaches as a side effect. Her hypertension is currently managed with amlodipine, but she reports past difficulty in controlling her blood pressure when a previous medication was altered.
            CHRONIC MEDICAL CONDITIONS SIGNIFICANTLY AFFECTING CARE:
            Hypertension.
            
            MEDICATIONS:
            - Nitroglycerin (causes significant headaches)
            - Amlodipine for hypertension.
            
            EXTERNAL RECORDS REVIEWED:
            The patient has had stress tests and recent lab work within the last week with her cardiologist, Dr. Oliver. These records are to be reviewed and possibly integrated into the current examination system.
            
            PLAN:
            1. Perform cardiac enzyme tests, blood counts, and electrolytes to investigate ongoing chest pain.
            2. Coordinate with the patient’s cardiologist or his associates regarding current management and explore alternative medications for chest pain management due to adverse effects from nitroglycerin.
            3. Attempt to retrieve and review recent lab results conducted earlier this week to aid in current evaluation.
            4. Follow up with the patient regarding results once all tests and consultations are completed.
            
            DIFFERENTIAL DIAGNOSIS:
            The Differential Diagnosis includes, in no particular order and is not limited to:
            - Angina
            - Myocardial Infarction
            - Gastroesophageal Reflux Disease (GERD)
            - Costochondritis
            - Panic Attack
            - Pericarditis
            - Pulmonary Embolism
            - Pneumothorax
            - Esophageal Spasm
            - Musculoskeletal Pain
            
            NONMEDICAL GESTURES:
            Used
            
            APPRECIATION OF THE PATIENTS TIME:
            Not Used`
        ],
      },
      careUpdateSection: {
        templateStructure: [
            `As a physician, your task is to generate concise updates or additional lines to be added to an existing patient note based on the provided transcript of
             a conversation during a reassessment, a discussion with a consultant, or any other updates regarding the patient's condition or treatment plan. Don't create section headings. Focus on capturing any new findings, changes in the patient’s status, or modifications to the treatment strategy. Ensure that the updates are specific, relevant, and clearly structured, only including information that was discussed during the interaction.
             
             Here is the transcription:
             {trans}`
        ],
        exampleNote: [
           `The patient is progressing positively. Initiation of antibiotics and vine inhalations is planned to facilitate recovery. An EKG has been requested to ensure a smooth transition to the general ward.`
        ],
      },
      dispositionSection: {
        templateStructure: [
           ` You are a {profession_type}. Create a note from the transcript of your conversation with the patient or you summarizing the visit (capitalize all letters of each section heading and don't have any ** before or after note sections). Structure the patient note with the sections provided below and add pertinent information to each section and do not include a section in the note if there is no information for that section. 
                DIAGNOSIS: (with ICD-10 code for each diagnosis)  (only include if explicitly mentioned)

                SUMMARY OF ENCOUNTER: 

                [Insert detailed narrative of why the patient was seen in the emergency department, management in the emergency department and the reasoning of why the patient was managed that way. ]
                DISPOSITION: (e.g. Discharge, Admit, Transfer, Left Against Medical Advice [AMA], Eloped, etc.) (only include if explicitly mentioned)
                CONSIDERATION FOR ADMISSION: (was their consideration to admit the patient, only include if explicitly mentioned)
                ASSESSMENT: (based on what you think maybe going on with the patient from the summary of encounter)
                EMERGENCY TREATMENTS ADMINISTERED: (medications provided in the emergency department): (only include if explicitly mentioned)
                MANAGEMENT OF THE PATIENTS CARE WAS DISCUSSED WITH: (Include details of any consultations with specialist, hospitalist, surgeon, etc.) (only include if explicitly mentioned)
                REASSESSMENT: (only include if explicitly mentioned)
                PLAN: (Include details of the management plan of the patient) (only include if explicitly mentioned)
                
                INDEPENDENT INTERPRETATION OF TESTS:
                Identify Labs Mentioned:

                Instruction: As you analyze the transcript, actively identify every laboratory test result mentioned (e.g., CBC, BMP, CMP, D-dimer, BNP) and ensure all lab values are included in the note. Do not miss any lab results, even if they are referenced indirectly (e.g., "leukocytosis" from a CBC or "elevated BNP").
                Chain-of-Thought Prompting:
                For each lab result mentioned, ask:
                Was a specific value or abnormality mentioned (e.g., elevated, low)?
                Was the lab interpreted by the provider (e.g., leukocytosis indicates infection)?
                Does the lab directly affect the clinical decision-making?


                Example:
                “Patient had leukocytosis”: This indicates an abnormal white blood cell count and should be captured as an independent interpretation of the CBC.
                “Elevated BNP”: This relates to potential fluid overload and should be included as an independent interpretation of the BMP.
                
                Format for Labs:
                Instruction: Ensure the following format is followed for labs:
                My independent interpretation of [Lab Type] is [Lab result or interpretation].
                Include Cross-Referenced Labs:
                Instruction: If any labs are cross-referenced from previous encounters (e.g., "renal function based on labs from two years ago"), include these in the interpretation section, noting the comparison.
                Example: "My independent interpretation of BMP indicates decreased renal function, consistent with prior lab results from two years ago."
                
                Radiology Results:
                Identify Imaging Mentioned:
                
                Instruction: Actively look for any radiology or imaging results (e.g., X-rays, CT scans, ultrasounds) mentioned in the transcript. Ensure each imaging result is independently interpreted in the note.
                Chain-of-Thought Prompting:
                Was a specific imaging modality (e.g., chest x-ray, CT scan) mentioned in the transcript?
                Did the provider review the imaging directly and make an independent interpretation based on the findings?
                Was there any discrepancy between the provider’s interpretation and the official radiology report?
                
                Format for Radiology Interpretation:
                Instruction: Ensure the following format is used for radiology interpretation:
                My independent [Radiology type, e.g., chest x-ray] interpretation is [findings]. 
                If a discrepancy exists, note it explicitly.
                
                Example:
                "My independent interpretation of the chest x-ray shows a suspected lung nodule in the right upper lobe."
                Handling Multiple Radiology Tests:
                Instruction: If multiple radiology or imaging tests are mentioned, include independent interpretations for each test. Ensure they are presented clearly in the following format:
                Bullet point each unique radiology test for clarity.
                Instruction: Ensure that you receive credit for ordering, independently interpreting, and discussing management based on the radiology results.
                Instruction: If the provider’s interpretation differs from the radiologist’s official report, document the discrepancy and note any resulting change in patient management.
                ADDITIONAL TESTING AND IMAGING CONSIDERED: (labs or imaging that were considered, but not ordered): (Only include if specifically mentioned)
                PROCEDURES (Detail any procedures conducted, in a structured billable format: (Only include if specifically mentioned)
                PATIENT EDUCATION AND COUNSELING: [information provided to the patient in regards to their diagnosis and/or overall health): If not discussed, omit this section.]
                FOLLOW-UP INSTRUCTIONS:
                (Specify any plans for follow-up visits for specialists and/or follow-up with primary care "Please call the office immediately to schedule a follow-up visit" [tomorrow, days, weeks etc.] (only include if explicitly mentioned)]
                
                MEDICATION RECONCILIATION:
                1. Detect Prescriptions and Medications Administered: Ensure that any medication administered during the visit or prescribed upon discharge is documented in the Medication Reconciliation section.
                Explicitly Include Prescriptions: If the patient is given a prescription (e.g., for naproxen), it must be listed in this section along with any dose or administration details (e.g., one dose of naproxen provided and a prescription for naproxen).
                Administered Medications: If the patient is given any medications during the visit (e.g., a dose of naproxen), include this as well.
                2. Specific Trigger for Medication Reconciliation:
                Any mention of medications being administered or prescribed should immediately trigger the inclusion of the Medication Reconciliation section.
                Ensure all medications (including over-the-counter medications, prescription medications, or those administered during the visit) are captured.
                3. Include Section Even for Simple Medications: Ensure this section is included regardless of how simple the medication regimen is (e.g., a single prescription or dose).
                SMOKING CESSATION COUNSELING: > 3 minutes to 10 minutes:  The patient smokes [] (cross if smokes cigarettes) cigarettes, [] (cross if smokes cigars) cigars, [] cannabis (cross if smokes cannabis); [] vapes nicotine (cross if vapes nicotine); or [] (cross if uses chewing tobacco) uses chewing tobacco. I counseled them on cessation and informed them that smoking and/or the use of nicotine-containing products pose a significant health risk and can cause many serious health ailments, such as cancer, lung disease, heart disease, stroke, diabetes, hypertension, and premature death.  I encouraged them to consider cold-turkey cessation, or nicotine patches/gum to curb their cravings initially, and to discuss other potential prescription medication options with their PCP in follow-up to today's ED visit.  I encouraged them to pick a quit date and to try to stick to it.  I informed them that quitting is the most important thing they can do to improve their health now and in the future. (Include if the patient is a current smoker of cigarettes/cannabis/vapes nicotine/chewing tobacco).
                MEDICAL DECISION MAKING: (Number and Complexity of Problems Addressed, Data and Risk ) 
                 
                Number and Complexity of Problems Addressed:
                The patient presented with the chief complaint(s) mentioned above and both acute and chronic concerns were assessed. Considering my differential diagnosis listed above, the range of potential diagnoses and management options underscores the encounter's severity and complexity.
                Data: After reviewing the DDx and complexity of the case, the HPI and physical exam for the chief complaint(s) mentioned above, the appropriate tests and treatment were considered and initiated. Tests results were analyzed when reported (note the specific tests and imaging mentioned in the transcript) & have been included in the thought processes for diagnosis, evaluation, and treatment. The results that were impactful to the decision making have been identified.
                Risk: The patient's care plan was significantly limited or impacted by a social determinant of health. Decision for potential hospitalization for the chief complaint(s) mentioned above was contemplated. Furthermore, social determinants of health, such as homelessness, unemployment and/or polysubstance abuse were considered, while assessing the suitable disposition for the patient. The Patient’s current medication(s) were reviewed and the appropriate recommendations provided. Lastly, the patient and/or family have been given an opportunity to ask questions and exhibit an understanding of what happened today in their encounter.
                In addition, if any of these are mentioned in the transcript, include in the MDM Risk section statement: Consideration for the need of hospitalization or escalation of initial care, Prescription drug management and/or IV fluids, Radiation exposure from CT scan, or head/neck/torso x-rays, Diagnosis or treatment significantly limited by social determinants of health, Rigid musculoskeletal immobilization applied, Social Determinants of Health impacting Care Plan (unavailability or inaccessibility of healthcare, homelessness, unemployed, uninsured, alcohol dependence, substance abuse), CT scan with IV contrast, Diagnostic/clinical decision support tool utilized, Parenteral controlled substances, Medications requiring intensive monitoring for toxicity, anticoagulation therapy was administered, Physical restraints utilized, Decisions made to not resuscitate or to de-escalate care because of poor prognosis. 
                  
                CRITICAL CARE TIME: [ if mentioned, make this statement with the amount of time mentioned "I provided *** minutes of critical care time. Due to a high probability of clinically significant, life-threatening deterioration, the patient required my highest level of preparedness to intervene emergently.  I personally spent this critical care time directly managing the patient.  This critical care time included obtaining a history; examining the patient; reviewing pulse oximetry; ordering and review of studies; arranging urgent treatment and developing of a management plan; evaluation of patient's response to treatment; frequent reassessment; and discussions with other providers.  This critical care time was performed to assess and manage the high probability of imminent, life-threatening deterioration that could result in multiorgan failure.  It was exclusive of separately billable procedures. (only include critical care time mentioned).
                When appropriate, add the following statements at the and make them appropriate to the summary. To the best of my knowledge and according to current medical standards, the patient was provided with care that meets or exceeds the standard of care for their condition.
                Ensure that if the patient's name is mentioned in the conversation transcript, it is excluded from the generated patient note.
                Exclude Summary Statements: Do not include any concluding statements, reflective summaries, or commentary about the note itself.
                If a section is not discussed, do not include that section in the note, as opposed to putting none discussed for that section. 
                Include only sections with content discussed in the note. Exclude any section that lacks content, rather than filling it with 'None discussed,' 'None mentioned,' 'Not applicable', 'None' or similar terms. 
                Do not mention anything about the transcript that the note was created from. 
                When creating a note, ensure it follows this structured order (Do not include any section which is not discussed in the transcription):
                
                DIAGNOSIS:
                SUMMARY OF ENCOUNTER: 
                DISPOSITION:
                CONSIDERATION FOR ADMISSION:
                ASSESSMENT: 
                EMERGENCY TREATMENTS ADMINISTERED:
                MANAGEMENT OF THE PATIENTS CARE WAS DISCUSSED WITH: 
                REASSESSMENT:
                PLAN:  
                INDEPENDENT INTERPRETATION OF TESTS:
                ADDITIONAL TESTING AND IMAGING CONSIDERED: 
                PROCEDURES:
                PATIENT EDUCATION AND COUNSELING:
                FOLLOW-UP INSTRUCTIONS:
                MEDICATION RECONCILIATION:
                SMOKING CESSATION COUNSELING:
                MEDICAL DECISION MAKING:
                CRITICAL CARE TIME: 
                The transcription is:
                {trans}`
        ],
        exampleNote: [
            `SUMMARY OF ENCOUNTER
            The patient, a 57-year-old female, presented with nausea, vomiting, diarrhea, and abdominal pain. Upon investigation, it was determined that the patient had an elevated lipase level at 106, suggesting pancreatitis. The vomiting, possibly related to Terzepatide use, is suspected to have aggravated the pancreas. Management in the emergency department included administering intravenous fluids for hydration and controlling pain. While the CT scan appeared normal, the decision was made to admit the patient to allow for further observation and management.

            DISPOSITION
            Admit

            CONSIDERATION FOR ADMISSION
            The patient was considered for admission due to elevated pancreatic enzymes indicative of pancreatitis and the need for further monitoring and treatment.

            ASSESSMENT
            The patient is likely experiencing pancreatitis, potentially exacerbated by Terzepatide use and related vomiting.

            EMERGENCY TREATMENTS ADMINISTERED
            Zofran was administered for nausea.

            PLAN
            The plan includes continuing intravenous fluids for hydration, pain control, and further observation of pancreatic enzyme levels. The patient will be handed off to the internal medicine team for overnight observation.

            INDEPENDENT INTERPRETATION OF TESTS
            My independent interpretation of the lipase test indicates an elevated level of 106, suggestive of pancreatic inflammation.

            PATIENT EDUCATION AND COUNSELING
            The patient was informed about their pancreatic condition, and the role vomiting may have played in aggravating it. Information about the function of the pancreas and its involvement in glucose metabolism was provided.

            MEDICATION RECONCILIATION
            Zofran will be provided upon discharge for nausea management.

            MEDICAL DECISION MAKING
            The patient presented with acute pancreatitis likely exacerbated by medication and symptoms of vomiting. The decision to admit was based on the elevated pancreatic enzyme levels, which indicate a need for monitoring and further treatment. Prescription drug management was considered with the administration of Zofran, and IV fluids were initiated to aid in rehydration. Further observation and care by the internal medicine team were deemed necessary for effective management of the condition.`
                        
        ],
      },
      ekgSection: {
        templateStructure: [
            `Summarize the findings from the EKG interpretation and start the findings with the statement "My independent EKG interpretation is". Only include details mentioned such as rhythm, heart rate, any notable intervals (e.g., PR interval, QRS duration, QT interval), the axis, and any abnormalities observed (e.g., ST segment changes, T wave inversions, arrhythmias) and Format EKG findings as individual lines.
            
            Here is the transcription:
            {trans}`
        ],
        exampleNote: [
           `My independent EKG interpretation is:
            - Rhythm: Not specified
            - Heart Rate: Not specified
            - PR Interval: Not specified
            - QRS Duration: Not specified
            - QT Interval: Not specified
            - Axis: Not specified
            - Abnormalities: D-R interval is slightly high
            - Additional Observations: Abnormalities in D-Hard`
        ],
      },
    },
    "hospitalist-template": {
        title: "Hospitalist Template",
        noteSection: {
          templateStructure: [
              `As an hospitalist, your task is to generate a comprehensive patient note from the provided transcript of a conversation between you and your patient or from you summarizing the visit. If layman's words are used by you, translate to more medically professional terms in the note. Your focus should be on capturing the essence and specifics of the discussion, translating it into a structured, concise, and detailed patient note. Follow these guidelines to ensure the note is both accurate and reflective of the conversation and do not include sections in the note that were not discussed (don't make a note of saying something was not discussed, just don't include that information in the note):
                
              Instructions:
                
                Incorporate Only Discussed Topics: 
                Your note should include note sections strictly based on the information explicitly mentioned in the conversation. Do not include note sections if they were not specifically discussed. Avoid placeholders for undiscussed note sections.
                
                Detail oriented Information Representation: 
                Aim to capture the conversation's details accurately in the note's relevant sections. This includes:
                
                For History of Present Illness: 
                Provide a narrative that captures the onset, duration, character, associated symptoms, aggravating or relieving factors, and the impact of symptoms on the patient's daily life. Include direct quotes from the patient when relevant to convey their experience accurately.
                
                For Other Sections: 
                Reflect the specifics as discussed, avoiding assumptions or generalizations not directly supported by the conversation.
                
                Structured Note Sections: 
                Follow the structured sections as listed, ensuring each section is populated based on the conversation.
                
                Additional Instructions for Enhanced Detail:
                
                Clarify Temporal Relationships: 
                When detailing the History of Present Illness, clarify the timeline of symptoms and any relevant sequential relationships.
                
                Symptom Description Enhancement: 
                Encourage the inclusion of descriptive qualifiers for symptoms.
                
                Patient Quotes for Symptom Context: 
                Where applicable, include patient quotes to provide context and insight into their subjective experience.
                
                Explicitly Requested Information: 
                If the conversation includes the patient or physician specifying tests, treatments, or follow-ups, detail these in the plan with direct reference to the discussion.
                
                Structured Note Sections: 
                Follow the structured sections as listed (capitalize all letters of each section and don't have any **), ensuring each section is populated based on the conversation, for Differential Diagnosis section, provide at least 10 most common Differential Diagnosis based on chief Complaint(s):
                
                :SERVICE DATE AND TIME: [insert date & time here] (must include)
                :CODE STATUS: (only state status and no other dialogue, and only include if discussed, otherwise omit this section)
                :PRIMARY CARE PROVIDER: (only include if applicable, otherwise omit this section)
                :CHIEF COMPLAINT(S): 
                :HISTORY OF PRESENT ILLNESS:
                
                Paragraph #1: Age, sex, past medical history, and chief complaint in sentence format (e.g., "78-year-old female with PMH of DM2, HLD, HTN who presents with painless jaundice.")
                Paragraph #2: Patient’s subjective story.
                Paragraph #3: ER course - vitals, labs, imaging, interventions in the ER, consults made, and reason for admission.
                
                :PAST MEDICAL HISTORY: (only include if applicable, otherwise omit this section)
                :PAST SURGICAL HISTORY:  (only include if applicable, otherwise omit this section)
                :FAMILY HISTORY: (only include if applicable, otherwise omit this section)
                :SOCIAL HISTORY:  (only include if applicable, otherwise omit this section)
                :MEDICATIONS PRIOR TO ADMISSION: 
                :ALLERGIES: (only include if applicable, otherwise omit this section)
                :REVIEW OF SYSTEMS: (only include if applicable, otherwise omit this section)
                :VITALS: (only include if applicable, otherwise omit this section)
                :PHYSICAL EXAM: (only include if applicable, otherwise omit this section)
                :LINES/DRAINS/AIRWAY: (only include if applicable, otherwise omit this section)
                :LABS: (only include if applicable, otherwise omit this section)
                :IMAGING: (only include if applicable, otherwise omit this section)
                
                :ASSESSMENT AND PLAN:
                
                Problem #1: (e.g., Painless Jaundice)
                
                Subjective Data to Support: Patient with 1-week painless jaundice, dark urine, and clay-colored stools.
                Objective Data to Support: Labs with elevated bilirubin, alk phos, AST/ALT; CT with dilated CBD and intrahepatic ducts, no mass seen.
                Differential Diagnosis: Choledocholithiasis, pancreatic mass, or stricture.
                
                Plan:
                Consult GI
                NPO at midnight
                Pain control, etc.
                Problem #2:
                Subjective Data to Support:
                Objective Data to Support:
                Differential Diagnosis:
                
                Plan:
                :DVT PROPHYLAXIS: (only include if applicable, otherwise omit this section)
                :PRESSURE ULCER: (only include if applicable, otherwise omit this section)
                :OBESITY: (only include if applicable, otherwise omit this section)
                :NUTRITION STATUS: (only include if applicable, otherwise omit this section)
                :DISPOSITION: (only include if applicable, otherwise omit this section)
                :PLAN OF CARE :DISCUSSED WITH: (only include if applicable, otherwise omit this section)
                :BILL BY TIME: (only include if applicable, otherwise omit this section)
                
                Additional Instructions for Enhanced Detail:
                
                Clarify Temporal Relationships: 
                When detailing the History of Present Illness, clarify Onset, Duration, Severity, Associated symptoms (only include if pertinent and write this section as a narrative)
                
                Symptom Description Enhancement: 
                Encourage the inclusion of descriptive qualifiers for symptoms.
                
                Patient Quotes for Symptom Context: 
                Where applicable, include patient quotes to provide context and insight into their subjective experience.
                
                Explicitly Requested Information: 
                If the conversation includes the patient or physician specifying tests, treatments, or follow-ups, detail these in the plan with direct reference to the discussion.
                
                Note on Patient Name: 
                If the patient’s name is mentioned in the conversation transcript, do not include it in the generated patient note. Instead of the patient's name, refer to them as "the patient" throughout the note. If family members or other individuals are mentioned, their names should be anonymized or replaced with generic terms like “the spouse” or “family member” to maintain privacy.
                
                Specific Guidelines:
                
                When the patient's name is mentioned: 
                Replace the name with “the patient” in all sections of the note.
                
                If other names (e.g., family members) are mentioned: 
                Replace with “the wife,” “the father,” “the grandmother,” etc., to maintain privacy and avoid confusion.
                
                No mention of names: 
                The final note should include no specific names. All references should be generalized (e.g., “the patient’s spouse,” “family members,” etc.).
                
                For the Differential Diagnosis list, do not include a note, such as "Note: 
                The differential diagnoses listed are based on the chief complaints and the common causes of these symptoms in a pediatric patient. Further diagnostic workup"
                
                For Physical Exam findings, don't make extra commentary about the exam not mentioned in the conversation.
                
                Exclude Summary Statements: 
                Do not include any concluding statements, reflective summaries, or commentary about the note itself.
                
                If a section is not discussed, do not include that section in the note, as opposed to putting none discussed for that section.
                Do not mention anything about the transcript that the note was created from. 
                The patient's age and gender are {patient_age} and {patient_sex}
                
                Here is the transcription:
                {trans}`
          ],
          exampleNote: [
              `SERVICE DATE AND TIME:

                CHIEF COMPLAINT(S): 
                Neck pain, low back pain, right elbow pain, right knee pain, and right leg pain following a motor vehicle collision.
                
                HISTORY OF PRESENT ILLNESS:
                A 77-year-old male presents to the emergency department with complaints of neck pain, low back pain, right elbow pain, right knee pain, and right leg pain following a motor vehicle collision that occurred earlier this morning. The patient reports being a restrained passenger in a vehicle that was rear-ended while stopped at a stop sign. The impact caused the patient to jolt forward and then backward again. He denies any loss of consciousness, headaches, nausea, vomiting, numbness, tingling, or urinary or bowel incontinence. After the collision, he was able to exit the vehicle and ambulate. The patient states he was able to attend a friend’s funeral afterward but decided to come to the emergency room for further evaluation post-event.
                
                PHYSICAL EXAM:
                - Cervical spine: Right lateral tenderness involving the splenius muscle with general tenderness; full range of motion observed; neurologically intact.
                - Upper extremities: Full strength and motor function; no dropping syndrome noted.
                - Thoracic and lumbar spine: Right lateral lumbar muscle tenderness; no saddle anesthesia; sensory and motor functions intact.
                - Lower extremities: No saddle anesthesia; sensory and motor functions intact; posterior calf tenderness evident, with full range of motion; compartment soft; pulses present.
                
                ASSESSMENT AND PLAN:
                Problem 1: 
                Musculoskeletal pain following motor vehicle collision
                
                Subjective Data to Support: 
                The patient reports neck pain, low back pain, right elbow pain, right knee pain, and right leg pain after being rear-ended in an accident.
                
                Objective Data to Support: 
                Physical examination reveals right lateral tenderness in the splenius muscle, cervical spine tenderness, lumbar muscle tenderness, and posterior calf tenderness. Neurologically intact with full range of motion in the examined areas.
                
                Differential Diagnosis: 
                Whiplash injury, cervical strain, lumbar sprain, elbow contusion, knee contusion, calf strain, soft tissue injury, muscle spasm, fracture (less likely given full range of motion and strength), ligamentous injury.
                
                Plan:
                - Recommend rest and avoidance of strenuous activities.
                - Prescribe analgesics for pain management.
                - Encourage application of cold packs to affected areas intermittently for swelling.
                - Advise follow-up with a primary care provider for persistent symptoms or the need for further imaging.
                - Educate patient on red flags: worsening pain, new neurological deficits, or changes in bowel/bladder function warrant immediate re-evaluation.`
          ],
        },
        careUpdateSection: {
          templateStructure: [
              `As a physician, your task is to generate concise updates or additional lines to be added to an existing patient note based on the provided transcript of
               a conversation during a reassessment, a discussion with a consultant, or any other updates regarding the patient's condition or treatment plan. Don't create section headings. Focus on capturing any new findings, changes in the patient’s status, or modifications to the treatment strategy. Ensure that the updates are specific, relevant, and clearly structured, only including information that was discussed during the interaction.
               
               Here is the transcription:
               {trans}`
          ],
          exampleNote: [
             `Care Plan:
              Patient was provided wound care instructions, prescribed antibiotics, and received a tetanus booster.
              
              Follow-Up:
              Patient instructed to return for follow-up in 48 hours to reassess wound healing and infection risk.`
          ],
        },
        dispositionSection: {
          templateStructure: [
             ` You are a {profession_type}. Create a note from the transcript of your conversation with the patient or you summarizing the visit (capitalize all letters of each section heading and don't have any ** before or after note sections). Structure the patient note with the sections provided below and add pertinent information to each section and do not include a section in the note if there is no information for that section. 
                  DIAGNOSIS: (with ICD-10 code for each diagnosis)  (only include if explicitly mentioned)
  
                  SUMMARY OF ENCOUNTER: 
  
                  [Insert detailed narrative of why the patient was seen in the emergency department, management in the emergency department and the reasoning of why the patient was managed that way. ]
                  DISPOSITION: (e.g. Discharge, Admit, Transfer, Left Against Medical Advice [AMA], Eloped, etc.) (only include if explicitly mentioned)
                  CONSIDERATION FOR ADMISSION: (was their consideration to admit the patient, only include if explicitly mentioned)
                  ASSESSMENT: (based on what you think maybe going on with the patient from the summary of encounter)
                  EMERGENCY TREATMENTS ADMINISTERED: (medications provided in the emergency department): (only include if explicitly mentioned)
                  MANAGEMENT OF THE PATIENTS CARE WAS DISCUSSED WITH: (Include details of any consultations with specialist, hospitalist, surgeon, etc.) (only include if explicitly mentioned)
                  REASSESSMENT: (only include if explicitly mentioned)
                  PLAN: (Include details of the management plan of the patient) (only include if explicitly mentioned)
                  
                  INDEPENDENT INTERPRETATION OF TESTS:
                  Identify Labs Mentioned:
  
                  Instruction: As you analyze the transcript, actively identify every laboratory test result mentioned (e.g., CBC, BMP, CMP, D-dimer, BNP) and ensure all lab values are included in the note. Do not miss any lab results, even if they are referenced indirectly (e.g., "leukocytosis" from a CBC or "elevated BNP").
                  Chain-of-Thought Prompting:
                  For each lab result mentioned, ask:
                  Was a specific value or abnormality mentioned (e.g., elevated, low)?
                  Was the lab interpreted by the provider (e.g., leukocytosis indicates infection)?
                  Does the lab directly affect the clinical decision-making?
  
  
                  Example:
                  “Patient had leukocytosis”: This indicates an abnormal white blood cell count and should be captured as an independent interpretation of the CBC.
                  “Elevated BNP”: This relates to potential fluid overload and should be included as an independent interpretation of the BMP.
                  
                  Format for Labs:
                  Instruction: Ensure the following format is followed for labs:
                  My independent interpretation of [Lab Type] is [Lab result or interpretation].
                  Include Cross-Referenced Labs:
                  Instruction: If any labs are cross-referenced from previous encounters (e.g., "renal function based on labs from two years ago"), include these in the interpretation section, noting the comparison.
                  Example: "My independent interpretation of BMP indicates decreased renal function, consistent with prior lab results from two years ago."
                  
                  Radiology Results:
                  Identify Imaging Mentioned:
                  
                  Instruction: Actively look for any radiology or imaging results (e.g., X-rays, CT scans, ultrasounds) mentioned in the transcript. Ensure each imaging result is independently interpreted in the note.
                  Chain-of-Thought Prompting:
                  Was a specific imaging modality (e.g., chest x-ray, CT scan) mentioned in the transcript?
                  Did the provider review the imaging directly and make an independent interpretation based on the findings?
                  Was there any discrepancy between the provider’s interpretation and the official radiology report?
                  
                  Format for Radiology Interpretation:
                  Instruction: Ensure the following format is used for radiology interpretation:
                  My independent [Radiology type, e.g., chest x-ray] interpretation is [findings]. 
                  If a discrepancy exists, note it explicitly.
                  
                  Example:
                  "My independent interpretation of the chest x-ray shows a suspected lung nodule in the right upper lobe."
                  Handling Multiple Radiology Tests:
                  Instruction: If multiple radiology or imaging tests are mentioned, include independent interpretations for each test. Ensure they are presented clearly in the following format:
                  Bullet point each unique radiology test for clarity.
                  Instruction: Ensure that you receive credit for ordering, independently interpreting, and discussing management based on the radiology results.
                  Instruction: If the provider’s interpretation differs from the radiologist’s official report, document the discrepancy and note any resulting change in patient management.
                  ADDITIONAL TESTING AND IMAGING CONSIDERED: (labs or imaging that were considered, but not ordered): (Only include if specifically mentioned)
                  PROCEDURES (Detail any procedures conducted, in a structured billable format: (Only include if specifically mentioned)
                  PATIENT EDUCATION AND COUNSELING: [information provided to the patient in regards to their diagnosis and/or overall health): If not discussed, omit this section.]
                  FOLLOW-UP INSTRUCTIONS:
                  (Specify any plans for follow-up visits for specialists and/or follow-up with primary care "Please call the office immediately to schedule a follow-up visit" [tomorrow, days, weeks etc.] (only include if explicitly mentioned)]
                  
                  MEDICATION RECONCILIATION:
                  1. Detect Prescriptions and Medications Administered: Ensure that any medication administered during the visit or prescribed upon discharge is documented in the Medication Reconciliation section.
                  Explicitly Include Prescriptions: If the patient is given a prescription (e.g., for naproxen), it must be listed in this section along with any dose or administration details (e.g., one dose of naproxen provided and a prescription for naproxen).
                  Administered Medications: If the patient is given any medications during the visit (e.g., a dose of naproxen), include this as well.
                  2. Specific Trigger for Medication Reconciliation:
                  Any mention of medications being administered or prescribed should immediately trigger the inclusion of the Medication Reconciliation section.
                  Ensure all medications (including over-the-counter medications, prescription medications, or those administered during the visit) are captured.
                  3. Include Section Even for Simple Medications: Ensure this section is included regardless of how simple the medication regimen is (e.g., a single prescription or dose).
                  SMOKING CESSATION COUNSELING: > 3 minutes to 10 minutes:  The patient smokes [] (cross if smokes cigarettes) cigarettes, [] (cross if smokes cigars) cigars, [] cannabis (cross if smokes cannabis); [] vapes nicotine (cross if vapes nicotine); or [] (cross if uses chewing tobacco) uses chewing tobacco. I counseled them on cessation and informed them that smoking and/or the use of nicotine-containing products pose a significant health risk and can cause many serious health ailments, such as cancer, lung disease, heart disease, stroke, diabetes, hypertension, and premature death.  I encouraged them to consider cold-turkey cessation, or nicotine patches/gum to curb their cravings initially, and to discuss other potential prescription medication options with their PCP in follow-up to today's ED visit.  I encouraged them to pick a quit date and to try to stick to it.  I informed them that quitting is the most important thing they can do to improve their health now and in the future. (Include if the patient is a current smoker of cigarettes/cannabis/vapes nicotine/chewing tobacco).
                  MEDICAL DECISION MAKING: (Number and Complexity of Problems Addressed, Data and Risk ) 
                   
                  Number and Complexity of Problems Addressed:
                  The patient presented with the chief complaint(s) mentioned above and both acute and chronic concerns were assessed. Considering my differential diagnosis listed above, the range of potential diagnoses and management options underscores the encounter's severity and complexity.
                  Data: After reviewing the DDx and complexity of the case, the HPI and physical exam for the chief complaint(s) mentioned above, the appropriate tests and treatment were considered and initiated. Tests results were analyzed when reported (note the specific tests and imaging mentioned in the transcript) & have been included in the thought processes for diagnosis, evaluation, and treatment. The results that were impactful to the decision making have been identified.
                  Risk: The patient's care plan was significantly limited or impacted by a social determinant of health. Decision for potential hospitalization for the chief complaint(s) mentioned above was contemplated. Furthermore, social determinants of health, such as homelessness, unemployment and/or polysubstance abuse were considered, while assessing the suitable disposition for the patient. The Patient’s current medication(s) were reviewed and the appropriate recommendations provided. Lastly, the patient and/or family have been given an opportunity to ask questions and exhibit an understanding of what happened today in their encounter.
                  In addition, if any of these are mentioned in the transcript, include in the MDM Risk section statement: Consideration for the need of hospitalization or escalation of initial care, Prescription drug management and/or IV fluids, Radiation exposure from CT scan, or head/neck/torso x-rays, Diagnosis or treatment significantly limited by social determinants of health, Rigid musculoskeletal immobilization applied, Social Determinants of Health impacting Care Plan (unavailability or inaccessibility of healthcare, homelessness, unemployed, uninsured, alcohol dependence, substance abuse), CT scan with IV contrast, Diagnostic/clinical decision support tool utilized, Parenteral controlled substances, Medications requiring intensive monitoring for toxicity, anticoagulation therapy was administered, Physical restraints utilized, Decisions made to not resuscitate or to de-escalate care because of poor prognosis. 
                    
                  CRITICAL CARE TIME: [ if mentioned, make this statement with the amount of time mentioned "I provided *** minutes of critical care time. Due to a high probability of clinically significant, life-threatening deterioration, the patient required my highest level of preparedness to intervene emergently.  I personally spent this critical care time directly managing the patient.  This critical care time included obtaining a history; examining the patient; reviewing pulse oximetry; ordering and review of studies; arranging urgent treatment and developing of a management plan; evaluation of patient's response to treatment; frequent reassessment; and discussions with other providers.  This critical care time was performed to assess and manage the high probability of imminent, life-threatening deterioration that could result in multiorgan failure.  It was exclusive of separately billable procedures. (only include critical care time mentioned).
                  When appropriate, add the following statements at the and make them appropriate to the summary. To the best of my knowledge and according to current medical standards, the patient was provided with care that meets or exceeds the standard of care for their condition.
                  Ensure that if the patient's name is mentioned in the conversation transcript, it is excluded from the generated patient note.
                  Exclude Summary Statements: Do not include any concluding statements, reflective summaries, or commentary about the note itself.
                  If a section is not discussed, do not include that section in the note, as opposed to putting none discussed for that section. 
                  Include only sections with content discussed in the note. Exclude any section that lacks content, rather than filling it with 'None discussed,' 'None mentioned,' 'Not applicable', 'None' or similar terms. 
                  Do not mention anything about the transcript that the note was created from. 
                  When creating a note, ensure it follows this structured order (Do not include any section which is not discussed in the transcription):
                  
                  DIAGNOSIS:
                  SUMMARY OF ENCOUNTER: 
                  DISPOSITION:
                  CONSIDERATION FOR ADMISSION:
                  ASSESSMENT: 
                  EMERGENCY TREATMENTS ADMINISTERED:
                  MANAGEMENT OF THE PATIENTS CARE WAS DISCUSSED WITH: 
                  REASSESSMENT:
                  PLAN:  
                  INDEPENDENT INTERPRETATION OF TESTS:
                  ADDITIONAL TESTING AND IMAGING CONSIDERED: 
                  PROCEDURES:
                  PATIENT EDUCATION AND COUNSELING:
                  FOLLOW-UP INSTRUCTIONS:
                  MEDICATION RECONCILIATION:
                  SMOKING CESSATION COUNSELING:
                  MEDICAL DECISION MAKING:
                  CRITICAL CARE TIME: 
                  The transcription is:
                  {trans}`
          ],
          exampleNote: [
              `Disposition Summary:
              Patient discharged with instructions for wound care and monitoring. Antibiotics prescribed for 7 days.
              
              Discharge Instructions:
              Patient advised to monitor for signs of infection and return immediately if symptoms worsen or systemic signs like fever develop.`
              
          ],
        },
        ekgSection: {
            templateStructure: [
                `Summarize the findings from the EKG interpretation and start the findings with the statement "My independent EKG interpretation is". Only include details mentioned such as rhythm, heart rate, any notable intervals (e.g., PR interval, QRS duration, QT interval), the axis, and any abnormalities observed (e.g., ST segment changes, T wave inversions, arrhythmias) and Format EKG findings as individual lines.
                
                Here is the transcription:
                {trans}`
            ],
            exampleNote: [
               `Care Plan:
                Patient was provided wound care instructions, prescribed antibiotics, and received a tetanus booster.
                
                Follow-Up:
                Patient instructed to return for follow-up in 48 hours to reassess wound healing and infection risk.`
            ],
          },
      },
};


export default metadata;