import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Button,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2"
import { useDispatch, useSelector } from "react-redux";
import metadata from "../../utils/metadataForPrompts";
import useApiRequest from "../../hooks/useHandleRequests";
import { updatePrompt } from "../../slices/promptsSlice";
import { useAuth } from "@clerk/clerk-react";

const DynamicPromptPage = () => {
  const { promptName } = useParams();
  const promptData = metadata[promptName];
  const [activeTab, setActiveTab] = useState(0);
  const [activeContent, setActiveContent] = useState("template");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const selectedPromptTitle = useSelector(
    (state) => state?.prompts?.prompts[0]?.prompt_title
  );

  const dispatch = useDispatch();
  const { userId } = useAuth();

  const { apiRequest } = useApiRequest({
    handleResponse: (data) => {
      dispatch(updatePrompt({ updatedData: data?.prompt }));
      setIsSubmitting(false);
    },
    handleError: (err) => {
      console.error("Error updating prompt:", err);
      setIsSubmitting(false);
    },
  });

  useEffect(() => {
    document.title = promptData?.title || "Prompt Not Found";
  }, [promptData]);

  if (!promptData) {
    return (
      <Box sx={{ textAlign: "center", mt: 5 }}>
        <Typography variant="h4" color="error">
          Prompt Not Found
        </Typography>
      </Box>
    );
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleUseTemplate = async () => {
    if (!userId) {
      console.error("User is not authenticated.");
      return;
    }

    setIsSubmitting(true);

    try {
      await apiRequest(`/v2/prompt/${userId}`, "put", {
        prompt_title: promptData.title,
        prompt_body: JSON.stringify(promptData),
      });

      console.log("Template updated successfully.");
    } catch (error) {
      console.error("Failed to update template:", error);
    }
  };

  const renderContent = () => {
    const sections = [
      promptData.noteSection,
      promptData.careUpdateSection,
      promptData.dispositionSection,
      promptData.ekgSection
    ];
  
    const currentSection = sections[activeTab];
  
    return (
      <Box
        sx={{
          border: "1px solid #D1D5DB",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF",
          p: 2,
          mt: 3,
        }}
      >
        {/* Toggle Buttons */}
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            p: 1,
          }}
        >
          <Button
            onClick={() => setActiveContent("template")}
            sx={{
              flex: 1,
              fontSize: "14px",
              fontWeight: "bold",
              color: activeContent === "template" ? "#FFFFFF" : "#202224",
              backgroundColor: activeContent === "template" ? "#007BFF" : "#F3F4F6",
              border: activeContent === "template" ? "none" : "1px solid #E0E0E0",
              textTransform: "none",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: activeContent === "template" ? "#0056b3" : "#E3F2FD",
              },
            }}
          >
            📋 Template Structure
          </Button>
          <Button
            onClick={() => setActiveContent("example")}
            sx={{
              flex: 1,
              fontSize: "14px",
              fontWeight: "bold",
              color: activeContent === "example" ? "#FFFFFF" : "#202224",
              backgroundColor: activeContent === "example" ? "#007BFF" : "#F3F4F6",
              border: activeContent === "example" ? "none" : "1px solid #E0E0E0",
              textTransform: "none",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: activeContent === "example" ? "#0056b3" : "#E3F2FD",
              },
            }}
          >
            📝 Example Note
          </Button>
        </Box>
  
        {/* Content Section */}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            {activeContent === "template" &&
              currentSection.templateStructure.map((text, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      whiteSpace: "pre-line",
                      color: "#000000de",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    {text}
                  </Typography>
                </Box>
              ))}
  
            {activeContent === "example" &&
              currentSection.exampleNote.map((text, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      whiteSpace: "pre-line",
                      color: "#000000de",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    {text}
                  </Typography>
                </Box>
              ))}
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  return (
    <Box
      sx={{
        maxWidth: "1200px",
        margin: "auto",
        mt: 4,
        px: 2,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
          textAlign: "left",
          fontSize: "32px",
          color: "#1A202C",
          mb: 3,
        }}
      >
        {promptData.title}
      </Typography>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{
          mb: 3,
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .MuiTab-root": {
            textTransform: "none",
            fontWeight: 600,
            fontSize: "16px",
            borderRadius: "5px",
            minHeight: "40px",
            minWidth: "120px",
            color: "#202224",
            backgroundColor: "#F3F4F6",
            marginRight: "8px",
            border: "1px solid #E0E0E0",
            boxShadow: "0px 1.54px 1.54px rgba(0, 0, 0, 0.25)",
            "&:hover": {
              backgroundColor: "#E3F2FD",
            },
          },
          "& .Mui-selected": {
            color: "#ffffff !important",
            backgroundColor: "#007BFF !important",
            border: "1px solid #007BFF",
            boxShadow: "0px 1.54px 1.54px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Tab label="Notes Section" />
        <Tab label="Care Update Section" />
        <Tab label="Disposition Section" />
        <Tab label="EKG Section" />
      </Tabs>

      {renderContent()}

      <Button
        variant="contained"
        onClick={handleUseTemplate}
        disabled={isSubmitting || selectedPromptTitle === promptData.title}
        sx={{
          backgroundColor: isSubmitting || selectedPromptTitle === promptData.title
            ? "#ccc" 
            : "#007BFF",
          color: "#fff",
          fontWeight: 600,
          textTransform: "none",
          width: "100%",
          mt: 4,
          py: 1.5,
          "&:hover": {
            backgroundColor:
              isSubmitting || selectedPromptTitle === promptData.title
                ? "#ccc"
                : "#155A9C",
          },
        }}
      >
        {isSubmitting ? (
          <CircularProgress size={24} sx={{ color: "#fff" }} />
        ) : selectedPromptTitle === promptData.title ? (
          "Selected"
        ) : (
          "Use Template"
        )}
      </Button>
    </Box>
  );
};

export default DynamicPromptPage;
