import React, { useEffect } from 'react';
import { Box, Typography, Button, Card, CardContent, CircularProgress } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate, useLocation } from 'react-router-dom';

const Success = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [apiCalled, setApiCalled] = React.useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const sessionId = urlParams.get('session_id');

    if (!sessionId) {
      setError('Missing session ID. Please contact support.');
      setLoading(false);
      return;
    }

    if (apiCalled) return; 

    const confirmSubscription = async () => {
      setApiCalled(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_URL}/api/v2/stripe/success`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sessionId }),
        });

        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.error || 'Failed to confirm subscription.');
        }

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    confirmSubscription();
  }, [location.search, apiCalled]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bgcolor="#f5f5f5"
      textAlign="center"
      minHeight="100vh"
      padding={2}
    >
      <Card
        sx={{
          maxWidth: 400,
          padding: 4,
          borderRadius: 3,
          boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
        }}
      >
        <CardContent>
          {loading ? (
            <CircularProgress sx={{ color: '#2E6FF3', marginBottom: 2 }} />
          ) : error ? (
            <>
              <ErrorOutlineIcon
                sx={{ fontSize: 60, color: 'red', marginBottom: 2 }}
              />
              <Typography variant="h5" fontWeight="bold" color="error" gutterBottom>
                Subscription Failed
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                {error}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate('/')}
                sx={{
                  marginTop: 3,
                  paddingX: 4,
                  borderRadius: 50,
                }}
              >
                Go Back Home
              </Button>
            </>
          ) : (
            <>
              <CheckCircleOutlineIcon
                sx={{ fontSize: 60, color: '#2E6FF3', marginBottom: 2 }}
              />
              <Typography variant="h4" fontWeight="bold" gutterBottom>
                Subscription Successful!
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Thank you for subscribing! Your subscription is now active, and you
                can enjoy all the features we offer.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/all-notes')}
                sx={{
                  marginTop: 3,
                  paddingX: 4,
                  borderRadius: 50,
                  background: 'linear-gradient(90deg,#3774c3,#2E6FF3)',
                }}
              >
                Go to Dashboard
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Success;
