import { Add, Close } from '@mui/icons-material'
import { Card, CardContent, IconButton, Stack, Typography, Grid2 as Grid, List, ListItemButton, ListItemText, Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { closeTool } from '../../slices/clinicalToolSlice'
import { updateNoteDetails } from '../../slices/noteDetialsSlice'

const Pecarn = ({ data}) => {
  const dispatch = useDispatch();

  const savedResults = JSON.parse(localStorage.getItem('pecarnResults')) || [
    {
      section: data,
      selectedOptionId: null,
      result: null
    }
  ];

  const [results, setResults] = useState(savedResults);

  useEffect(() => {
    localStorage.setItem('pecarnResults', JSON.stringify(results));
  }, [results]);

  const getResult = () =>  results[results.length -2]?.result || null;
  return (
    <Card variant='elevation' elevation={3} sx={{width: '100%', marginBottom: '15px', backgroundColor: '#EDF2FF'}}>
      <CardContent>
        <Stack direction="row-reverse">
          <IconButton onClick={()=> {
            dispatch(closeTool())
            localStorage.removeItem('selectedTool')
            localStorage.removeItem('pecarnResults')
          }} size='large' color='primary'>
            <Close />
          </IconButton>
        </Stack>
        <Typography color='#202224' fontWeight={500} fontSize="20px">
            PECARN Pediatric Head Injury/Trauma Algorithm
        </Typography>
        {
          results && results.map((result, index) => {

            

            return !result.section ? '' :  (
              <Grid sx={{ margin: '15px 0px'}} container component="main" spacing={3}>
          <Grid size={{ lg: 6, md: 6, sm: 6, xs: 6}}>
            <Typography>
              {result.section.name}
            </Typography>
            <Typography>
              {result.section?.description}
            </Typography>
          </Grid>
          <Grid size={{ lg: 6, md: 6, sm: 6, xs: 6}}>
          <List sx={{ bgcolor: 'white', margin: '10px 0px', padding: '0px', borderRadius: '8px'}}>
          {
            result && result.section.options.map((option) => {
              const selected = result.selectedOptionId === option.id
              if(selected || result.selectedOptionId === null){
                return (
                  <ListItemButton
                  selected={selected}
                  onClick={()=>{
                    if(!selected || true){
                    setResults((results)=>{
                      const newResults = [...results];
                      newResults[index] = {
                        ...newResults[index],
                        selectedOptionId: selected ? null : option.id,
                        result: selected ? null : option.result || null
                      }
                      newResults[index + 1] = {
                        section: option?.section || null,
                        selectedOptionId: null,
                        result: null
                      }
                      return newResults.slice(0,selected ? index + 1 : index + 2);
                    })
                  }
                  }}
                  dense
                  sx={{
                    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
                    '&.Mui-selected': {
                      backgroundColor: '#2E6FF3',
                      color: 'white'
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: '#2E6FF3',
                      color: 'white'
                    }
                  }}
            >
              <ListItemText
                primary={option.label}
                primaryTypographyProps={{
                  fontSize: '10px',
                  fontWeight: 500
                }}
              />
            </ListItemButton>
                )
              }
              return null
            })
          }
          </List>
          </Grid>
        </Grid>
            )
          })
        }
        <Box padding="15px" bgcolor="white" margin="10px 0px">
          <Typography color='#202224' fontSize="14px" fontWeight={500} maxWidth="80%">
            {getResult()}
          </Typography>
          <Stack direction="row-reverse">
            <Button disabled={!getResult()} onClick={()=> {
              localStorage.setItem('Dtool', true)
              dispatch(updateNoteDetails(
                {key: 'summary_json', value: {
                heading: 'PECARN:',
                key: 'PECARN Pediatric Head Injury/Trauma Algorithm',
                content: getResult()
              }}
            ));
            localStorage.removeItem('selectedTool')
            localStorage.removeItem('pecarnResults')
            dispatch(closeTool())
            }
              } sx={{ bgcolor: '#2E6FF3'}} variant='contained' color='primary' size='large' startIcon={<Add />}>
              Insert
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  )
}

export default Pecarn